import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import {theme} from '../../../tailwind.config'

const CastStudyList = ({post, size = ""}) => {
    let url = `/case-study${post.node.fields.slug}`;

    return (
        <Link to={url} className="flex flex-col justify-between p-10 text-white rounded-xl shadow-xl bg-purple hover:shadow-2xl transition-all bg-primary" >
            <h2 className="font-black text-2xl">
                {post.node.frontmatter.userQuote}
            </h2>
            { size !== "sm" && (<p className="mt-6 text-base">{post.node.frontmatter.companyShortDescription}</p>) }
            <div className="flex items-end justify-between">
                <div className="author flex gap-4 items-center mt-6 text-left">
                    <div className="flex items-center"><img className="rounded-full w-14" alt={post.node.frontmatter.userName} src={post.node.frontmatter.userPhoto}/></div>
                    <div className="flex flex-col">
                        <span className="text-base">{post.node.frontmatter.userName}</span>
                        <span className="text-sm uppercase opacity-75">{post.node.frontmatter.userRole}</span>
                    </div>
                </div>
                <div>
                    <i className="fal fa-circle-arrow-right"></i>
                </div>
            </div>

        </Link>
    )
}

export default CastStudyList

