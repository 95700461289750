import React from "react"
import CaseStudy from './_caseStudy'

const CaseStudyList = ({posts, size = ""}) => {

    return (
        <div className="grid lg:grid-cols-3 justify-around gap-12 mt-24 m-auto">
            {posts.map(post => {
                return (<CaseStudy post={post} size={size}/>)
            })}
        </div>
    )
}

export default CaseStudyList

